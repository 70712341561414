@import "main.css";
#fullHeight {
    height: 90vh; /* Ajuste la hauteur à 90% de la hauteur de l'écran */
    max-height: 90vh; /* Empêche de dépasser 90% de la hauteur de l'écran */
}

.video-background-content {
    padding-top: 2rem; /* Ajoute un léger espacement supérieur */
    padding-bottom: 2rem; /* Ajoute un léger espacement inférieur */
}


#navigation-bar-hide {
opacity: 1 !important;
top: 0 !important;
}

.navbar {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.logo-footer > img {
filter: brightness(0) invert(1);
}

.container.videoTitle {
    margin-bottom: 28rem;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in forwards; /* Fades in after a delay */
    animation-delay: 4s; /* 4 seconds delay */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



/*------------------------------------ Patchwork egal */
@media all and (min-width: 990px) {
div.row-cols-1 >div.col {
width : 33.3333333333% !important;
}
}

/*------------------------------------ Bouton retour en haut */
.btnHaut {
  position: fixed;
  bottom: 50px; right: 0;
  padding: 10px 3px 10px 20px;
  background: #F2812F;
  color: white;
  text-decoration: none;
  border-radius : 30px 0 0 30px !important;
z-index: 10;
}

.btnHaut:hover {
  background: #FFC093;
color: white;
}

/*----------------------------------------  Boutons Réseaux-Sociaux */
.btnFB {
  position: fixed;
  bottom: 120px; right: 0;
  padding: 10px 3px 10px 20px;
  background: #F2812F;
  color: white;
  text-decoration: none;
  border-radius : 30px 0 0 30px !important;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
z-index: 10;
}

.btnFB:hover {
  background: #FFC093;
color: white;
}


.divBtnRDV {

text-align : center;

margin-bottom: 50px;
margin-top: 50px;

}

.websitePageTitle-div {
display : none !important;
}

/* Style normal*/
.btnRDV {

font-family: 'Montserrat';

font-size: 1.25rem;

padding: 10px 20px;

background: #F2812F;

color: black;

text-decoration: none;

border-radius : 20px;

}

/* Style HOVER*/
.btnRDV:hover {

background: #FFC093;

color : black;

transition: all 0.4s cubic-bezier(.86, .01, .15, .99);

}

.btn-custom {
    display: block;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: normal; /* Texte en regular */
    color: white;
    background-color: #F2812F;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
    max-width: 150px; /* Largeur fixe pour un bouton plus standard */
}

.btn-custom:hover {
    background-color: #FFC093; /* Nouveau fond au survol */
}




.underline {
    width: 70%; /* Plus long */
    height: 1px;
    background-color: #F2812F;
    margin: 10px 0; /* Espacement autour de l'underline */
    z-index: 2; /* Priorité visuelle */
    position: relative; /* Permet le chevauchement correct */
}



.cta_banner > .background-image-div-opacity {
    background-attachment: initial !important;
}

.socialsMiroir-div > .background-image-div-opacity {
    background-attachment: fixed !important;
}
